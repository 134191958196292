import { defineComponent as _defineComponent } from 'vue';
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = { class: "d-flex-center" };
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { logoutAPI, modifyPwdAPI } from '@/api/auth';
import { ElForm, ElLoading, ElMessage } from 'element-plus';
import { useRouter } from 'vue-router';
import { removeToken } from '@utils/auth';
import { password, required } from '@utils/form-rule';
export default /*@__PURE__*/ _defineComponent({
    __name: 'user-info',
    setup: function (__props) {
        var store = useStore();
        var router = useRouter();
        var userInfo = computed(function () { return store.getters['app/userInfo']; });
        // region TODO 登出
        function logout() {
            var loading = ElLoading.service({ text: '正在安全退出' });
            logoutAPI().then(function (res) {
                res.result().then(function () {
                    removeToken();
                    ElMessage.success('已安全登出');
                    router.push({ name: 'Login' });
                });
            }).finally(loading.close);
        }
        // endregion
        // region TODO 修改密码
        // modifyUserInfoAPI
        var pwdDialogModal = ref(false);
        var pwdDialogConnect = ref(false);
        var pwdDialogForm = ref({});
        var pwdDialogFormRef = ref();
        var pwdDialogFormRules = ref({
            password: [required('旧密码为必填项')],
            newPassWord: [required('新密码必填项'), password],
            confirmPassWord: [required('确认密码必填项'), {
                    validator: function (rule, value, callback) {
                        callback(value !== pwdDialogForm.value.newPassWord ? Error('两次密码不一致') : undefined);
                    },
                    trigger: 'blur'
                }],
        });
        watch(pwdDialogModal, function (nv) {
            if (!nv) {
                pwdDialogFormRef.value.resetFields();
                pwdDialogForm.value = {};
            }
        });
        function pwdDialogFormSubmit() {
            pwdDialogFormRef.value.validate().then(function () {
                var loading = ElLoading.service({ text: '正在提交' });
                modifyPwdAPI(pwdDialogForm.value).then(function (res) {
                    res.result().then(function () {
                        ElMessage.success('修改成功，需重新登录');
                        removeToken();
                        router.replace({ name: 'Login' });
                        pwdDialogModal.value = false;
                    });
                }).finally(loading.close);
            });
        }
        // endregion
        return function (_ctx, _cache) {
            var _component_icon_base_avatar = _resolveComponent("icon-base-avatar");
            var _component_el_icon = _resolveComponent("el-icon");
            var _component_el_link = _resolveComponent("el-link");
            var _component_el_dropdown_item = _resolveComponent("el-dropdown-item");
            var _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu");
            var _component_el_dropdown = _resolveComponent("el-dropdown");
            var _component_el_dialog = _resolveComponent("el-dialog");
            var _component_el_input = _resolveComponent("el-input");
            var _component_el_form_item = _resolveComponent("el-form-item");
            var _component_el_button = _resolveComponent("el-button");
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_el_icon, { class: "color-primary-2 fs-22" }, {
                    default: _withCtx(function () { return [
                        _createVNode(_component_icon_base_avatar)
                    ]; }),
                    _: 1
                }),
                _createVNode(_component_el_dropdown, null, {
                    dropdown: _withCtx(function () { return [
                        _createVNode(_component_el_dropdown_menu, null, {
                            default: _withCtx(function () { return [
                                _createVNode(_component_el_dropdown_item, {
                                    onClick: _cache[0] || (_cache[0] = function ($event) { return (_unref(router).push({ name: 'UserInfo' })); })
                                }, {
                                    default: _withCtx(function () { return _cache[9] || (_cache[9] = [
                                        _createTextVNode("账号信息")
                                    ]); }),
                                    _: 1
                                }),
                                _createVNode(_component_el_dropdown_item, {
                                    onClick: _cache[1] || (_cache[1] = function ($event) { return (pwdDialogModal.value = true); })
                                }, {
                                    default: _withCtx(function () { return _cache[10] || (_cache[10] = [
                                        _createTextVNode("修改密码")
                                    ]); }),
                                    _: 1
                                }),
                                _createVNode(_component_el_dropdown_item, {
                                    onClick: _cache[2] || (_cache[2] = function ($event) { return (pwdDialogConnect.value = true); })
                                }, {
                                    default: _withCtx(function () { return _cache[11] || (_cache[11] = [
                                        _createTextVNode("联系我们")
                                    ]); }),
                                    _: 1
                                }),
                                _createVNode(_component_el_dropdown_item, { onClick: logout }, {
                                    default: _withCtx(function () { return _cache[12] || (_cache[12] = [
                                        _createTextVNode("登出")
                                    ]); }),
                                    _: 1
                                })
                            ]; }),
                            _: 1
                        })
                    ]; }),
                    default: _withCtx(function () { return [
                        _createVNode(_component_el_link, {
                            underline: false,
                            class: "user-info_name color-primary-2"
                        }, {
                            default: _withCtx(function () { return [
                                _createTextVNode(_toDisplayString(userInfo.value.displayName || '用户名'), 1)
                            ]; }),
                            _: 1
                        })
                    ]; }),
                    _: 1
                }),
                _createVNode(_component_el_dialog, {
                    title: "联系我们",
                    modelValue: pwdDialogConnect.value,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) { return ((pwdDialogConnect).value = $event); }),
                    width: "500px"
                }, {
                    default: _withCtx(function () { return _cache[13] || (_cache[13] = [
                        _createElementVNode("div", {
                            class: "d-flex-between-center flex-column fs-16",
                            style: { "height": "150px" }
                        }, [
                            _createElementVNode("span", { class: "fs-18" }, "上海悦狮传动科技有限公司"),
                            _createElementVNode("span", null, "电话：13526978239"),
                            _createElementVNode("span", null, "邮箱：yueshichuandong@163.com")
                        ], -1)
                    ]); }),
                    _: 1
                }, 8, ["modelValue"]),
                _createVNode(_component_el_dialog, {
                    title: "修改密码",
                    modelValue: pwdDialogModal.value,
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = function ($event) { return ((pwdDialogModal).value = $event); }),
                    "close-on-click-modal": false,
                    width: "480px"
                }, {
                    footer: _withCtx(function () { return [
                        _createVNode(_component_el_button, {
                            onClick: _cache[7] || (_cache[7] = function ($event) { return (pwdDialogModal.value = false); })
                        }, {
                            default: _withCtx(function () { return _cache[14] || (_cache[14] = [
                                _createTextVNode("关 闭")
                            ]); }),
                            _: 1
                        }),
                        _createVNode(_component_el_button, {
                            onClick: pwdDialogFormSubmit,
                            type: "primary"
                        }, {
                            default: _withCtx(function () { return _cache[15] || (_cache[15] = [
                                _createTextVNode("提交")
                            ]); }),
                            _: 1
                        })
                    ]; }),
                    default: _withCtx(function () { return [
                        _createVNode(_unref(ElForm), {
                            model: pwdDialogForm.value,
                            ref_key: "pwdDialogFormRef",
                            ref: pwdDialogFormRef,
                            rules: pwdDialogFormRules.value,
                            "label-width": "80px"
                        }, {
                            default: _withCtx(function () { return [
                                _createVNode(_component_el_form_item, {
                                    prop: "password",
                                    label: "旧密码"
                                }, {
                                    default: _withCtx(function () { return [
                                        _createVNode(_component_el_input, {
                                            modelValue: pwdDialogForm.value.password,
                                            "onUpdate:modelValue": _cache[4] || (_cache[4] = function ($event) { return ((pwdDialogForm.value.password) = $event); }),
                                            "show-password": ""
                                        }, null, 8, ["modelValue"])
                                    ]; }),
                                    _: 1
                                }),
                                _createVNode(_component_el_form_item, {
                                    prop: "newPassWord",
                                    label: "新密码"
                                }, {
                                    default: _withCtx(function () { return [
                                        _createVNode(_component_el_input, {
                                            modelValue: pwdDialogForm.value.newPassWord,
                                            "onUpdate:modelValue": _cache[5] || (_cache[5] = function ($event) { return ((pwdDialogForm.value.newPassWord) = $event); }),
                                            "show-password": ""
                                        }, null, 8, ["modelValue"])
                                    ]; }),
                                    _: 1
                                }),
                                _createVNode(_component_el_form_item, {
                                    prop: "confirmPassWord",
                                    label: "确认密码"
                                }, {
                                    default: _withCtx(function () { return [
                                        _createVNode(_component_el_input, {
                                            modelValue: pwdDialogForm.value.confirmPassWord,
                                            "onUpdate:modelValue": _cache[6] || (_cache[6] = function ($event) { return ((pwdDialogForm.value.confirmPassWord) = $event); }),
                                            "show-password": ""
                                        }, null, 8, ["modelValue"])
                                    ]; }),
                                    _: 1
                                })
                            ]; }),
                            _: 1
                        }, 8, ["model", "rules"])
                    ]; }),
                    _: 1
                }, 8, ["modelValue"])
            ]));
        };
    }
});
