import { __awaiter, __generator } from "tslib";
import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle } from "vue";
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { computed, onMounted, ref, watch } from 'vue';
import { getMenuList } from '@/router/menu-factory';
import MenuItem from '@/layout/components/sidebar/menu-bar/MenuItem.vue';
import { getLeftMenuListAPI } from '@/api/device';
import YkSubfield from '@/components/subfield/YkSubfield.vue';
import { useCollapseStore } from '@/layout/components/tools/collapse/store';
export default /*@__PURE__*/ _defineComponent({
    __name: 'MenuBar',
    props: {
        expandWidth: {}
    },
    setup: function (__props) {
        var _this = this;
        var props = __props;
        var subfieldWidth = ref(0);
        var sideCollapse = useCollapseStore();
        var store = useStore();
        var route = useRoute();
        onMounted(function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                subfieldWidth.value = props.expandWidth;
                getMenuListData();
                activeMenu(route);
                return [2 /*return*/];
            });
        }); });
        // region TODO 监听选中菜单
        var defaultActive = ref('');
        watch(function () { return route.name; }, function (nv, ov) {
            activeMenu(route);
        });
        // onBeforeRouteUpdate((e) => {
        //   activeMenu(e)
        // })
        function activeMenu(route) {
            defaultActive.value = ((route === null || route === void 0 ? void 0 : route.meta.activeMenu) || route.path);
        }
        // endregion
        // 缩放状态
        var collapse = computed(function () { return store.getters['layout/collapse']; });
        // 菜单列表
        var menuList = ref([]);
        function getMenuListData() {
            var list = getMenuList();
            return getLeftMenuListAPI().then(function (res) {
                res.result().then(function (rdata) {
                    var data = rdata.payload.data;
                    // id 加密
                    list[1].children = recursionFormat(data);
                    menuList.value = list;
                });
            });
        }
        function recursionFormat(list) {
            var ret = [];
            list.forEach(function (item) {
                var _a, _b;
                var c = {
                    path: '/device-list/c' + item.addressId,
                    meta: { title: item.addressName },
                    children: []
                };
                if ((_a = item.item) === null || _a === void 0 ? void 0 : _a.length) {
                    c.children = recursionFormat(item.item);
                }
                if ((_b = item.deviceMessages) === null || _b === void 0 ? void 0 : _b.length) {
                    item.deviceMessages.forEach(function (device) {
                        c.children.push({
                            // path: '/device-list/' + encrypt(device.deviceId.toString()),
                            path: '/device-list/' + device.deviceId.toString(),
                            meta: { title: device.deviceName }
                        });
                    });
                }
                ret.push(c);
            });
            return ret;
        }
        return function (_ctx, _cache) {
            var _component_el_menu = _resolveComponent("el-menu");
            return (_openBlock(), _createElementBlock("div", null, [
                _createVNode(YkSubfield, {
                    "current-width": subfieldWidth.value,
                    "onUpdate:currentWidth": _cache[0] || (_cache[0] = function ($event) { return ((subfieldWidth).value = $event); }),
                    width: !_unref(sideCollapse).collapse ? props.expandWidth : null,
                    class: "overflow-auto menu-bar",
                    "min-width": props.expandWidth,
                    "max-width": 400,
                    "out-of-box": false,
                    style: _normalizeStyle({ maxWidth: subfieldWidth.value + 'px' })
                }, {
                    default: _withCtx(function () { return [
                        _createVNode(_component_el_menu, {
                            collapse: collapse.value,
                            "default-active": defaultActive.value
                        }, {
                            default: _withCtx(function () { return [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menuList.value, function (menu) {
                                    return (_openBlock(), _createBlock(MenuItem, {
                                        key: menu.name,
                                        menu: menu
                                    }, null, 8, ["menu"]));
                                }), 128))
                            ]; }),
                            _: 1
                        }, 8, ["collapse", "default-active"])
                    ]; }),
                    _: 1
                }, 8, ["current-width", "width", "min-width", "style"])
            ]));
        };
    }
});
