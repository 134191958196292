import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = { style: { "width": "140px" } };
var _hoisted_2 = {
    class: "fs-24 fw-7",
    style: { "letter-spacing": "5px" }
};
var _hoisted_3 = { class: "d-flex fs-12 mt-2 d-flex align-items-center" };
import { onBeforeUnmount, onMounted, ref } from 'vue';
import { moment } from '@utils/moment';
export default /*@__PURE__*/ _defineComponent({
    __name: 'time-box',
    setup: function (__props) {
        onMounted(function () {
            startTime();
        });
        // region TODO 计时器
        var timer = null;
        var datetime = ref({
            date: '',
            time: '',
            week: ''
        });
        function startTime() {
            timer = setInterval(function () {
                var _a = moment().format('YYYY-MM-DD,HH:mm:ss,dddd').split(','), date = _a[0], time = _a[1], week = _a[2];
                datetime.value = { date: date, time: time, week: week };
            }, 500);
        }
        function stopTime() {
            timer && clearInterval(timer);
        }
        onBeforeUnmount(function () {
            stopTime();
        });
        // endregion
        return function (_ctx, _cache) {
            var _component_el_divider = _resolveComponent("el-divider");
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, _toDisplayString(datetime.value.time), 1),
                _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", null, _toDisplayString(datetime.value.date), 1),
                    _createVNode(_component_el_divider, { direction: "vertical" }),
                    _createElementVNode("div", null, _toDisplayString(datetime.value.week), 1)
                ])
            ]));
        };
    }
});
