import { onMounted, ref, watch } from 'vue';
import { ElLoading } from 'element-plus';
import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, isRef as _isRef, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '@images/btn-help.png';
import _imports_1 from './images/upload.png';
import _imports_2 from './images/download.png';
var _hoisted_1 = { class: "d-flex-around-center" };
var _hoisted_2 = { class: "document-box" };
var _hoisted_3 = { class: "document-box" };
import { ElMessage, ElMessageBox } from 'element-plus';
import { getHelpDocumentAPI, setHelpDocumentAPI, uploadFileAPI } from '@/api/file';
import settings from '@/settings';
export default /*@__PURE__*/ _defineComponent({
    __name: 'help-document',
    setup: function (__props) {
        onMounted(function () {
        });
        // region TODO 帮助文档
        var helpFile = ref([]);
        watch(helpFile, function (nv, ov) {
            if (!ov.length && nv.length) {
                ElMessageBox.confirm('将【' + helpFile.value[0].name + '】上传为帮助文档？', '上传提醒').then(function () {
                    var loading = ElLoading.service({ text: '正在上传文件' });
                    uploadFileAPI(helpFile.value[0].raw).then(function (res) {
                        res.result().then(function (rdata) {
                            loading.setText('正在保存');
                            setHelpDocumentAPI({ text: rdata.payload.data }).then(function (res) {
                                res.result().then(function () {
                                    ElMessage.success('上传成功');
                                    helpFile.value = [];
                                });
                            }).finally(loading.close);
                        });
                    }).catch(loading.close);
                });
            }
        });
        var helpModel = ref(false);
        function getDocDocument() {
            getHelpDocumentAPI().then(function (res) {
                res.result().then(function (rdata) {
                    var data = rdata.payload.data;
                    if (data) {
                        var a = document.createElement('a');
                        a.href = settings.IMAGE_PREFIX + data;
                        a.target = '_blank';
                        a.click();
                    }
                    else {
                        ElMessage.info('没有帮助文档');
                    }
                });
            });
        }
        // endregion
        return function (_ctx, _cache) {
            var _component_el_button = _resolveComponent("el-button");
            var _component_elc_upload = _resolveComponent("elc-upload");
            var _component_el_dialog = _resolveComponent("el-dialog");
            return (_openBlock(), _createElementBlock("div", null, [
                _createElementVNode("img", {
                    onClick: _cache[0] || (_cache[0] = function ($event) { return (helpModel.value = true); }),
                    class: "cursor-pointer",
                    src: _imports_0
                }),
                _createVNode(_component_el_dialog, {
                    modelValue: _unref(helpModel),
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) { return (_isRef(helpModel) ? (helpModel).value = $event : null); }),
                    title: "帮助文档",
                    width: "585px"
                }, {
                    default: _withCtx(function () { return [
                        _createElementVNode("div", _hoisted_1, [
                            _createElementVNode("div", _hoisted_2, [
                                _cache[4] || (_cache[4] = _createElementVNode("img", { src: _imports_1 }, null, -1)),
                                _createVNode(_component_elc_upload, {
                                    modelValue: _unref(helpFile),
                                    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) { return (_isRef(helpFile) ? (helpFile).value = $event : null); }),
                                    "list-type": "none"
                                }, {
                                    default: _withCtx(function () { return [
                                        _createVNode(_component_el_button, {
                                            type: "primary",
                                            class: "mt-20"
                                        }, {
                                            default: _withCtx(function () { return _cache[3] || (_cache[3] = [
                                                _createTextVNode("上传文档")
                                            ]); }),
                                            _: 1
                                        })
                                    ]; }),
                                    _: 1
                                }, 8, ["modelValue"])
                            ]),
                            _createElementVNode("div", _hoisted_3, [
                                _cache[6] || (_cache[6] = _createElementVNode("img", { src: _imports_2 }, null, -1)),
                                _createVNode(_component_el_button, {
                                    onClick: getDocDocument,
                                    type: "success",
                                    class: "mt-20"
                                }, {
                                    default: _withCtx(function () { return _cache[5] || (_cache[5] = [
                                        _createTextVNode("下载文档")
                                    ]); }),
                                    _: 1
                                })
                            ])
                        ])
                    ]; }),
                    _: 1
                }, 8, ["modelValue"])
            ]));
        };
    }
});
