import { ref } from 'vue';
import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode } from "vue";
var _hoisted_1 = {
    class: "layout-container h-100",
    style: { "width": "1920px" }
};
var _hoisted_2 = {
    class: "d-flex",
    style: { "--view-height": "calc(var(--adaptive-height) - var(--header-height))", "height": "calc(100% - var(--header-height))" }
};
var _hoisted_3 = { class: "flex-1 w-0" };
import ToolsBar from '@/layout/components/toolsbar/ToolsBar.vue';
import MenuBar from '@/layout/components/sidebar/menu-bar/MenuBar.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LayoutView',
    setup: function (__props) {
        var expandWidth = ref(180);
        return function (_ctx, _cache) {
            var _component_router_view = _resolveComponent("router-view");
            var _component_el_scrollbar = _resolveComponent("el-scrollbar");
            var _component_adaptive_box = _resolveComponent("adaptive-box");
            return (_openBlock(), _createBlock(_component_adaptive_box, { mode: "full-height" }, {
                default: _withCtx(function () { return [
                    _createElementVNode("div", _hoisted_1, [
                        _createVNode(ToolsBar),
                        _createElementVNode("div", _hoisted_2, [
                            _createVNode(MenuBar, {
                                style: { "overflow": "auto" },
                                "expand-width": _unref(expandWidth)
                            }, null, 8, ["expand-width"]),
                            _createElementVNode("div", _hoisted_3, [
                                _createVNode(_component_el_scrollbar, null, {
                                    default: _withCtx(function () { return [
                                        _createVNode(_component_router_view, { class: "p-20" }, {
                                            default: _withCtx(function (_a) {
                                                var Component = _a.Component;
                                                return [
                                                    _createVNode(_Transition, {
                                                        name: "el-fade-in-linear",
                                                        mode: "out-in"
                                                    }, {
                                                        default: _withCtx(function () { return [
                                                            (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                                                        ]; }),
                                                        _: 2
                                                    }, 1024)
                                                ];
                                            }),
                                            _: 1
                                        })
                                    ]; }),
                                    _: 1
                                })
                            ])
                        ])
                    ])
                ]; }),
                _: 1
            }));
        };
    }
});
