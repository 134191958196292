import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import locale from 'element-plus/es/locale/lang/zh-cn';
export default /*@__PURE__*/ _defineComponent({
    __name: 'App',
    setup: function (__props) {
        return function (_ctx, _cache) {
            var _component_router_view = _resolveComponent("router-view");
            var _component_el_scrollbar = _resolveComponent("el-scrollbar");
            var _component_el_config_provider = _resolveComponent("el-config-provider");
            return (_openBlock(), _createBlock(_component_el_config_provider, { locale: _unref(locale) }, {
                default: _withCtx(function () { return [
                    _createVNode(_component_el_scrollbar, null, {
                        default: _withCtx(function () { return [
                            _createVNode(_component_router_view)
                        ]; }),
                        _: 1
                    })
                ]; }),
                _: 1
            }, 8, ["locale"]));
        };
    }
});
