import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
var _hoisted_1 = { class: "empty-box" };
var _hoisted_2 = {
    key: 0,
    class: "empty-box_error"
};
var _hoisted_3 = { class: "empty-box__image" };
var _hoisted_4 = ["src"];
import { onMounted, ref, watch } from 'vue';
import { EmptyEnum } from '@/components/norm-library/empty-box/type';
import { ObjectUtil } from '@utils/object-util';
export default /*@__PURE__*/ _defineComponent({
    __name: 'EmptyBox',
    props: {
        state: { type: [String, Boolean, Object] },
        imageSize: { default: 180 },
        emptyClass: {},
        emptyStyle: {}
    },
    setup: function (__props) {
        // 是否显示空状态
        var props = __props;
        onMounted(function () {
            updateStatus();
        });
        // 是否显示缺省
        var isShowEmpty = ref(true);
        // 当前缺省对象
        var currentEmpty = ref({});
        // 更新状态
        function updateStatus() {
            var _a;
            var emptyType = EmptyEnum.NONE;
            var tempEmpty = {};
            if (ObjectUtil.isBoolean(props.state) || ObjectUtil.isString(props.state)) {
                if (ObjectUtil.isBoolean(props.state)) {
                    emptyType = props.state ? EmptyEnum.SUCCESS : EmptyEnum.EMPTY;
                }
                else {
                    emptyType = props.state;
                }
                tempEmpty = statusConfig.get(emptyType);
            }
            else {
                var type = ((_a = props.state) === null || _a === void 0 ? void 0 : _a.type) || EmptyEnum.NONE;
                emptyType = type;
                tempEmpty = statusConfig.get(type);
                ObjectUtil.updateVal(tempEmpty, props.state || {});
            }
            if (emptyType === EmptyEnum.SUCCESS) {
                currentEmpty.value.type = EmptyEnum.SUCCESS;
                isShowEmpty.value = false;
            }
            else {
                tempEmpty.type = emptyType;
                currentEmpty.value = tempEmpty;
                isShowEmpty.value = true;
            }
        }
        var statusConfig = new Map([
            ['Empty', { src: require('./images/empty.png'), description: '暂无数据' }],
            ['EmptyVar', { src: require('./images/empty-var.png'), description: '当前无内容，请选择参数' }],
            ['Loading', { src: '', description: ' ' }],
            ['None', { src: '', description: ' ' }],
        ]);
        watch(function () { return props.state; }, updateStatus);
        return function (_ctx, _cache) {
            var _component_el_empty = _resolveComponent("el-empty");
            var _directive_loading = _resolveDirective("loading");
            return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                (isShowEmpty.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        _createVNode(_component_el_empty, {
                            "image-size": props.imageSize,
                            description: currentEmpty.value.description,
                            class: _normalizeClass(props.emptyClass),
                            style: _normalizeStyle(props.emptyStyle)
                        }, {
                            image: _withCtx(function () { return [
                                _createElementVNode("div", _hoisted_3, [
                                    (currentEmpty.value.src)
                                        ? (_openBlock(), _createElementBlock("img", {
                                            key: 0,
                                            src: currentEmpty.value.src
                                        }, null, 8, _hoisted_4))
                                        : _createCommentVNode("", true)
                                ])
                            ]; }),
                            _: 1
                        }, 8, ["image-size", "description", "class", "style"])
                    ]))
                    : _createCommentVNode("", true),
                _createElementVNode("div", {
                    class: _normalizeClass(["empty-box_content", { 'is-hide': isShowEmpty.value }])
                }, [
                    _renderSlot(_ctx.$slots, "default")
                ], 2)
            ])), [
                [_directive_loading, currentEmpty.value.type === _unref(EmptyEnum).LOADING]
            ]);
        };
    }
});
