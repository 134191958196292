import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = { class: "adaptive-box" };
import { onMounted, getCurrentInstance, ref, onBeforeUnmount, } from 'vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'AdaptiveBox',
    props: {
        reference: {},
        minWidth: {},
        maxWidth: {},
        updateWidth: { type: Boolean, default: false },
        observeNodeUpdate: { type: Boolean, default: false },
        mode: { default: 'aequilate' }
    },
    setup: function (__props, _a) {
        var __expose = _a.expose;
        var props = __props;
        onMounted(function () {
            initReference();
            resize();
            props.observeNodeUpdate && observeNode();
            window.addEventListener('resize', resize);
        });
        onBeforeUnmount(function () {
            window.removeEventListener('resize', resize);
            observer && observer.disconnect();
        });
        // 参考对象
        var reference = null;
        var adaptiveBoxRef = ref();
        var adaptiveContentRef = ref();
        // region TODO 初始化
        // 初始化参考对象
        function initReference() {
            var _a, _b;
            if (props.reference) {
                if (typeof props.reference === 'string') {
                    reference = (_b = (_a = getCurrentInstance()) === null || _a === void 0 ? void 0 : _a.parent) === null || _b === void 0 ? void 0 : _b.refs[props.reference];
                }
                else {
                    reference = props.reference;
                }
            }
            else {
                reference = window;
            }
            resizeHandel = resizeType[props.mode];
        }
        // endregion
        // region TODO 计算比例大小
        var resizeType = {
            aequilate: resizeIsAequilate,
            contain: resizeIsContain,
            'full-height': resizeIsFullHeight
        };
        var resizeHandel = null;
        // 重置大小
        function resize() {
            resizeHandel && resizeHandel();
        }
        // 根据宽度重置大小
        function resizeIsAequilate() {
            // 参考宽度
            var referenceWidth = reference.offsetWidth || reference.innerWidth;
            var $content = adaptiveContentRef.value;
            var scale = referenceWidth / $content.offsetWidth;
            var width = $content.offsetWidth * scale;
            if (props.minWidth && width < props.minWidth) {
                scale = props.minWidth / $content.scrollWidth;
                width = props.minWidth;
            }
            if (props.maxWidth && width > props.maxWidth) {
                scale = props.maxWidth / $content.scrollWidth;
                width = props.maxWidth;
            }
            $content.style.transform = "scale(".concat(scale, ")");
            // 计算缩放后所占的高度
            var height = $content.offsetHeight * scale;
            adaptiveBoxRef.value.style.height = "".concat(height, "px");
            if (props.updateWidth) {
                adaptiveBoxRef.value.style.width = "".concat(width, "px");
            }
        }
        function resizeIsContain() {
            // 参考宽高
            var referenceWidth, referenceHeight;
            if (window === reference) {
                referenceWidth = reference.innerWidth;
                referenceHeight = reference.innerHeight;
            }
            else {
                referenceWidth = reference.offsetWidth;
                referenceHeight = reference.offsetHeight;
            }
            var $content = adaptiveContentRef.value;
            var scale = referenceWidth / $content.offsetWidth;
            var height = $content.offsetHeight * scale;
            var width = $content.offsetWidth * scale;
            if (height > referenceHeight) {
                scale = referenceHeight / $content.offsetHeight;
                height = $content.offsetHeight * scale;
                width = $content.offsetWidth * scale;
            }
            $content.style.transform = "scale(".concat(scale, ")");
            adaptiveBoxRef.value.style.height = "".concat(height, "px");
            adaptiveBoxRef.value.style.width = "".concat(width, "px");
        }
        var fullHeight = ref('');
        function resizeIsFullHeight() {
            // 参考宽高
            var referenceWidth, referenceHeight;
            if (window === reference) {
                referenceWidth = reference.innerWidth;
                referenceHeight = reference.innerHeight;
            }
            else {
                referenceWidth = reference.offsetWidth;
                referenceHeight = reference.offsetHeight;
            }
            var $content = adaptiveContentRef.value;
            var scale = referenceWidth / $content.offsetWidth;
            var width = $content.offsetWidth * scale;
            if (props.minWidth && width < props.minWidth) {
                scale = props.minWidth / $content.scrollWidth;
                width = props.minWidth;
            }
            if (props.maxWidth && width > props.maxWidth) {
                scale = props.maxWidth / $content.scrollWidth;
                width = props.maxWidth;
            }
            $content.style.transform = "scale(".concat(scale, ")");
            // 计算缩放后所占的高度
            var height = referenceHeight / scale;
            adaptiveBoxRef.value.style.height = "".concat(referenceHeight, "px");
            adaptiveContentRef.value.style.height = fullHeight.value = "".concat(height, "px");
            if (props.updateWidth) {
                adaptiveBoxRef.value.style.width = "".concat(width, "px");
            }
        }
        // endregion
        // region TODO 观察节点变化
        var observer = null;
        // 开启监听节点变化
        function observeNode() {
            var config = {
                attributes: true,
                childList: true,
                subtree: true,
            };
            observer = new MutationObserver(resize);
            observer.observe(adaptiveBoxRef.value, config);
        }
        // endregion
        __expose({ resize: resize });
        return function (_ctx, _cache) {
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", {
                    class: "adaptive-box_body",
                    ref_key: "adaptiveBoxRef",
                    ref: adaptiveBoxRef
                }, [
                    _createElementVNode("div", {
                        class: "adaptive-box_content",
                        ref_key: "adaptiveContentRef",
                        ref: adaptiveContentRef,
                        style: _normalizeStyle({ '--adaptive-height': fullHeight.value })
                    }, [
                        _renderSlot(_ctx.$slots, "default")
                    ], 4)
                ], 512)
            ]));
        };
    }
});
