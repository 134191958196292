import { ref, onMounted, computed } from 'vue';
import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export default /*@__PURE__*/ _defineComponent({
    __name: 'YkSubfield',
    props: {
        width: { default: null },
        minWidth: { default: -Infinity },
        maxWidth: { default: Infinity },
        dragLineWidth: { default: 6 },
        outOfBox: { type: Boolean, default: true },
        currentWidth: { default: null }
    },
    emits: ['update:currentWidth'],
    setup: function (__props, _a) {
        var __emit = _a.emit;
        var props = __props;
        var emits = __emit;
        var subfieldWidth = ref(0);
        onMounted(function () {
            subfieldWidth.value = props.width;
        });
        // region TODO 拖拽线
        var dragLineStyle = computed(function () {
            return {
                width: props.dragLineWidth + 'px',
                right: (props.outOfBox ? -props.dragLineWidth : 0) + 'px'
            };
        });
        // endregion
        // region TODO 事件绑定
        // 按下时所在位置
        var downPageX = 0;
        var downWidth = 0;
        var isMoving = ref(false);
        function onMousedownHandle(e) {
            downPageX = e.pageX;
            downWidth = subfieldWidth.value;
            isMoving.value = true;
            document.addEventListener('mousemove', onMousemoveHandle);
            document.addEventListener('mouseup', onMouseupHandle);
        }
        function onMousemoveHandle(e) {
            var width = downWidth + (e.pageX - downPageX);
            if (width > props.maxWidth) {
                width = props.maxWidth;
            }
            else if (width < props.minWidth) {
                width = props.minWidth;
            }
            emits('update:currentWidth', width);
            subfieldWidth.value = width;
        }
        function onMouseupHandle() {
            isMoving.value = false;
            document.removeEventListener('mousemove', onMousemoveHandle);
            document.removeEventListener('mouseup', onMouseupHandle);
        }
        // endregion
        return function (_ctx, _cache) {
            return (_openBlock(), _createElementBlock("div", {
                class: "yk-subfield",
                style: _normalizeStyle({ width: props.width !== null ? _unref(subfieldWidth) + 'px' : 'fit-content' })
            }, [
                _renderSlot(_ctx.$slots, "default"),
                _createElementVNode("div", {
                    class: _normalizeClass(["drag-line", { 'is-moving': _unref(isMoving) }]),
                    style: _normalizeStyle(_unref(dragLineStyle)),
                    onMousedown: onMousedownHandle
                }, null, 38)
            ], 4));
        };
    }
});
