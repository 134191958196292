import { defineComponent as _defineComponent } from 'vue';
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { onBeforeUnmount, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { getAlarmListAPI } from '@/api/alarm';
import settings from '@/settings';
import { useStore } from 'vuex';
import { ElNotification } from 'element-plus';
export default /*@__PURE__*/ _defineComponent({
    __name: 'alarm-tip',
    setup: function (__props) {
        var store = useStore();
        var router = useRouter();
        onMounted(function () {
            getAlarm();
            initWebSocket();
        });
        // region TODO Badge
        var isDot = ref(false);
        function badgeClickHandle() {
            isDot.value = false;
            router.push({ name: 'Alarm' });
        }
        // endregion
        // region TODO 报警 Alarm
        function getAlarm() {
            getAlarmListAPI().then(function (res) {
                res.result().then(function (rdata) {
                    var data = rdata.payload.data || [];
                    isDot.value = data.length > 0;
                });
            });
        }
        // endregion
        // region TODO WebSocket
        var ws = null;
        var isReunion = true;
        function initWebSocket() {
            ws = new WebSocket(settings.WEBSOCKET + store.getters['app/userInfo'].id);
            ws.onopen = function () {
                console.log('已连接');
            };
            ws.onmessage = function (e) {
                var data = JSON.parse(e.data);
                if (data.payload.project) {
                    ElNotification.error(data.payload.project);
                }
            };
            ws.onclose = function () {
                console.log('已断开');
                if (isReunion) {
                    console.log('尝试重连');
                    initWebSocket();
                }
            };
        }
        function closeWebSocket() {
            isReunion = false;
            ws && ws.close();
        }
        onBeforeUnmount(function () {
            closeWebSocket();
        });
        // endregion
        return function (_ctx, _cache) {
            var _component_icon_base_notice = _resolveComponent("icon-base-notice");
            var _component_el_icon = _resolveComponent("el-icon");
            var _component_el_badge = _resolveComponent("el-badge");
            return (_openBlock(), _createElementBlock("div", null, [
                _createVNode(_component_el_badge, {
                    onClick: badgeClickHandle,
                    "is-dot": isDot.value
                }, {
                    default: _withCtx(function () { return [
                        _createVNode(_component_el_icon, {
                            class: "cursor-pointer",
                            size: "22px"
                        }, {
                            default: _withCtx(function () { return [
                                _createVNode(_component_icon_base_notice)
                            ]; }),
                            _: 1
                        })
                    ]; }),
                    _: 1
                }, 8, ["is-dot"])
            ]));
        };
    }
});
