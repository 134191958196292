import { defineComponent as _defineComponent } from 'vue';
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue";
import { onMounted, onUnmounted, ref } from 'vue';
import { ElMessage } from 'element-plus';
import screenfull from 'screenfull';
export default /*@__PURE__*/ _defineComponent({
    __name: 'screen-full',
    setup: function (__props) {
        onMounted(function () {
            if (screenfull.isEnabled) {
                screenfull.on('change', changeEvent);
            }
        });
        var isFullscreen = ref(false);
        var screenToggle = function () {
            if (!screenfull.isEnabled) {
                ElMessage.warning('您的浏览器不支持全屏功能');
                return false;
            }
            screenfull.toggle();
        };
        var changeEvent = function () {
            isFullscreen.value = screenfull.isFullscreen;
        };
        onUnmounted(function () {
            // 删除监听器
            if (screenfull.isEnabled) {
                screenfull.off('change', changeEvent);
            }
        });
        return function (_ctx, _cache) {
            var _component_icon_base_out_full_screen = _resolveComponent("icon-base-out-full-screen");
            var _component_icon_base_full_screen = _resolveComponent("icon-base-full-screen");
            var _component_el_icon = _resolveComponent("el-icon");
            return (_openBlock(), _createElementBlock("span", {
                onClick: screenToggle,
                class: "cursor-pointer screen-full"
            }, [
                _createVNode(_component_el_icon, { color: "#FFFFFF" }, {
                    default: _withCtx(function () { return [
                        (isFullscreen.value)
                            ? (_openBlock(), _createBlock(_component_icon_base_out_full_screen, { key: 0 }))
                            : (_openBlock(), _createBlock(_component_icon_base_full_screen, { key: 1 }))
                    ]; }),
                    _: 1
                })
            ]));
        };
    }
});
