import { defineComponent as _defineComponent } from 'vue';
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
export default /*@__PURE__*/ _defineComponent({
    __name: 'MenuItem',
    props: {
        menu: {}
    },
    setup: function (__props) {
        var props = __props;
        return function (_ctx, _cache) {
            var _component_el_menu_item = _resolveComponent("el-menu-item");
            var _component_router_link = _resolveComponent("router-link");
            var _component_menu_item = _resolveComponent("menu-item", true);
            var _component_el_sub_menu = _resolveComponent("el-sub-menu");
            return (!props.menu.children)
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 0,
                    to: props.menu.path
                }, {
                    default: _withCtx(function () { return [
                        _createVNode(_component_el_menu_item, {
                            index: props.menu.path
                        }, {
                            title: _withCtx(function () {
                                var _a;
                                return [
                                    _createElementVNode("span", null, _toDisplayString((_a = props.menu.meta) === null || _a === void 0 ? void 0 : _a.title), 1)
                                ];
                            }),
                            default: _withCtx(function () {
                                var _a, _b;
                                return [
                                    _createElementVNode("i", {
                                        class: _normalizeClass(["iconfont", [(_b = (_a = props.menu) === null || _a === void 0 ? void 0 : _a.meta) === null || _b === void 0 ? void 0 : _b.icon]])
                                    }, null, 2)
                                ];
                            }),
                            _: 1
                        }, 8, ["index"])
                    ]; }),
                    _: 1
                }, 8, ["to"]))
                : (_openBlock(), _createBlock(_component_el_sub_menu, {
                    key: 1,
                    index: props.menu.path
                }, {
                    title: _withCtx(function () {
                        var _a, _b;
                        return [
                            _createElementVNode("i", {
                                class: _normalizeClass(["iconfont", [(_a = props.menu.meta) === null || _a === void 0 ? void 0 : _a.icon]])
                            }, null, 2),
                            _createElementVNode("span", null, _toDisplayString((_b = props.menu.meta) === null || _b === void 0 ? void 0 : _b.title), 1)
                        ];
                    }),
                    default: _withCtx(function () { return [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.menu.children, function (child) {
                            return (_openBlock(), _createBlock(_component_menu_item, {
                                key: child.name,
                                menu: child
                            }, null, 8, ["menu"]));
                        }), 128))
                    ]; }),
                    _: 1
                }, 8, ["index"]));
        };
    }
});
