import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue";
import { computed, ref } from 'vue';
import { Plus, UploadFilled } from '@element-plus/icons-vue';
import { ElUpload } from 'element-plus';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ElcUpload',
    props: {
        modelValue: {},
        limit: { default: 1 },
        listType: { default: 'picture-card' },
        editMode: { type: Boolean, default: true },
        showFileList: { type: Boolean, default: true },
        accept: {},
        drag: { type: Boolean, default: false },
        uploadClass: { default: '' }
    },
    emits: ['update:modelValue'],
    setup: function (__props, _a) {
        var __expose = _a.expose, __emit = _a.emit;
        var props = __props;
        var emits = __emit;
        // region TODO 数据双向绑定
        // const { modelValue } = toRefs(props)
        var changeFileList = computed({
            get: function () {
                return props.modelValue;
            },
            set: function (val) {
                emits('update:modelValue', val);
            }
        });
        // endregion
        // region TODO 图片放大
        var urlList = computed(function () {
            return props.modelValue.map(function (item) { return item.url; });
        });
        var imageViewerState = ref(false);
        var initialIndex = ref(0);
        var uploadImageRef = ref(null);
        function handlePictureCardPreview(e) {
            if (props.listType === 'picture-card') {
                initialIndex.value = props.modelValue.findIndex(function (item) { return e.uid === item.uid; });
                imageViewerState.value = true;
            }
        }
        function hideImageViewer() {
            imageViewerState.value = false;
        }
        // endregion
        var CUploadRef = ref();
        // region TODO expose
        function clearFiles() {
            CUploadRef.value.clearFiles();
        }
        // endregion
        __expose({ clearFiles: clearFiles });
        return function (_ctx, _cache) {
            var _component_el_icon = _resolveComponent("el-icon");
            var _component_el_image_viewer = _resolveComponent("el-image-viewer");
            return (_openBlock(), _createElementBlock("div", null, [
                _createVNode(_unref(ElUpload), {
                    ref_key: "CUploadRef",
                    ref: CUploadRef,
                    class: _normalizeClass([props.editMode ? 'mode-edit' : 'mode-show', _ctx.modelValue.length >= props.limit ? 'is-max' : '', _ctx.uploadClass]),
                    action: "#",
                    limit: props.limit,
                    "file-list": changeFileList.value,
                    "onUpdate:fileList": _cache[0] || (_cache[0] = function ($event) { return ((changeFileList).value = $event); }),
                    "list-type": _ctx.listType,
                    "auto-upload": false,
                    "on-preview": handlePictureCardPreview,
                    drag: props.drag,
                    "show-file-list": _ctx.showFileList,
                    accept: props.accept
                }, {
                    default: _withCtx(function () { return [
                        (props.drag)
                            ? (_openBlock(), _createBlock(_component_el_icon, {
                                key: 0,
                                class: "el-icon--upload"
                            }, {
                                default: _withCtx(function () { return [
                                    _createVNode(_unref(UploadFilled))
                                ]; }),
                                _: 1
                            }))
                            : _renderSlot(_ctx.$slots, "default", { key: 1 }, function () { return [
                                (props.listType === 'picture-card')
                                    ? (_openBlock(), _createBlock(_component_el_icon, { key: 0 }, {
                                        default: _withCtx(function () { return [
                                            _createVNode(_unref(Plus))
                                        ]; }),
                                        _: 1
                                    }))
                                    : _createCommentVNode("", true)
                            ]; })
                    ]; }),
                    _: 3
                }, 8, ["class", "limit", "file-list", "list-type", "drag", "show-file-list", "accept"]),
                (imageViewerState.value)
                    ? (_openBlock(), _createBlock(_component_el_image_viewer, {
                        key: 0,
                        ref_key: "uploadImageRef",
                        ref: uploadImageRef,
                        "url-list": urlList.value,
                        "initial-index": initialIndex.value,
                        onClose: hideImageViewer
                    }, null, 8, ["url-list", "initial-index"]))
                    : _createCommentVNode("", true)
            ]));
        };
    }
});
