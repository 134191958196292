import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = { class: "tools-container" };
import ScreenFull from './components/screen-full.vue';
import UserInfo from './components/user-info.vue';
import OrganizationInfo from './components/title-info/title-Info.vue';
import TimeBox from '@/layout/components/toolsbar/components/time-box.vue';
import AlarmTip from '@/layout/components/toolsbar/components/alarm-tip.vue';
import HelpDocument from '@/layout/components/toolsbar/components/help-document/help-document.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ToolsBar',
    setup: function (__props) {
        return function (_ctx, _cache) {
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", null, [
                    _createVNode(OrganizationInfo)
                ]),
                _createElementVNode("div", null, [
                    _createVNode(HelpDocument),
                    _createVNode(AlarmTip),
                    _createVNode(ScreenFull),
                    _createVNode(UserInfo),
                    _createVNode(TimeBox)
                ])
            ]));
        };
    }
});
